import DOMPurify from 'dompurify';

const ALLOWED_TAGS = ['a', 'b', 'br'];
const hasAllowedTags = str => new RegExp(`<${ALLOWED_TAGS.join('|')}>`, 'i').test(str);

const buildMessageBox = (msg, template, errors) => {
  const fragment = document.createDocumentFragment();
  const wrapper = document.createElement('div');
  wrapper.innerHTML = template;
  const p = wrapper.querySelector('.c-alert__msg');
  const sanitzedMsg = DOMPurify.sanitize(msg, { ALLOWED_TAGS });

  const errorsList = errors
    ? `<ul class="u-no-m-b u-p-t-tiny">${errors
        .map(err => `<li class="u-bold">${DOMPurify.sanitize(err, { ALLOWED_TAGS })}</li>`)
        .join('')}</ul>`
    : '';

  // Some messages will have nice things, like anchor or bold tags. We detect those, sanitize and only then use innerHTML.
  // For some XSS strings, this will render gibberish (<img src=x />) but at least we get our precious links.
  // See https://github.com/cure53/DOMPurify for what else is allowed by default.
  if (hasAllowedTags(sanitzedMsg) || errorsList) {
    p.innerHTML = sanitzedMsg + errorsList;
  } else {
    p.innerText = msg;
  }

  return fragment.appendChild(wrapper);
};

/**
 * Name: flashMessages
 *
 * @param {string} message  - message to be shown
 * @param {string} template - which template to use. See @msgboxerror and @msgboxnotice in behaviours.js.coffee
 * @param {function} afterShow - callback for when the flash is shown
 * @param {array[string]} errors - a list of errors that can be shown alongside a message
 *
 */

window.$.fn.flashMessages = function flashMessages({ message, template, afterShow, errors }) {
  return this.each(() => {
    const $this = $(this);

    $this
      .css({ opacity: 0 })
      .html(buildMessageBox(message, template, errors))
      .fmwait(200)
      .then(() => {
        const $msg = $this.find('.msg');
        const lineHeight = parseFloat($msg.css('line-height'));
        const fontSize = parseFloat($msg.css('font-size'));
        // eslint-disable-next-line no-unused-expressions
        parseInt(lineHeight, 10) === $msg.height() /* for most browsers */ ||
        (lineHeight < fontSize &&
          parseInt(lineHeight * fontSize, 10) === $msg.height()) /* for IE as IE returns the line-height literally */
          ? $msg.addClass('single-line')
          : false;

        $this.animate({ opacity: 1 });

        // eslint-disable-next-line no-unused-expressions
        $.isFunction(afterShow) && afterShow.apply($this);
      });
  });
};

window.$.fn.fmwait = time => {
  return $.Deferred(dfd => {
    setTimeout(dfd.resolve, time);
  });
};
